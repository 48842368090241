import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SOURCES } from 'client/utils/image-helpers';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';
import { CarouselMetaContext } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/carousel-meta-context';
import { CarouselLazySlide } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/carousel-lazy-slide';
import { Dots } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/dots';
import { CustomPaging } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/custom-paging';
import { UsurpInventoryCardPhotoImage } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-photo-image';
import { UsurpInventoryCardCarouselArrowLeft } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/usurp-inventory-card-carousel-arrow-left';
import { UsurpInventoryCardCarouselArrowRight } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/usurp-inventory-card-carousel-arrow-right';
import { UsurpInventoryCardVdpLink } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-vdp-link';
import { InventoryEntities } from 'client/data/models/inventory';

const DEFAULT_ANIMATION_SPEED = 500;

export const UsurpInventoryCardCarousel = React.memo(
  ({
    photos,
    isMobile,
    photoType,
    tabIndex,
    vin,
    clientSideRenderImage,
    lazyLoadImage,
    importance,
    bodyType,
    vehicle,
    vdpLink,
    isLlmCard,
    searchId,
    isSimilarCards,
    isVdpPartnerListing,
    photoImgClassnames,
    isVinPreview,
    isShop2901Enabled,
  }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const isSwiping = useRef(false);

    const appendDots = useCallback(dots => <Dots dots={dots} photos={photos} />, [photos]);
    const customPaging = useCallback(i => <CustomPaging index={i} photos={photos} />, [photos]);

    const prevArrow = useMemo(
      () => <UsurpInventoryCardCarouselArrowLeft ariaLabel="previous photo" tabIndex={tabIndex} />,
      [tabIndex]
    );
    const nextArrow = useMemo(
      () => <UsurpInventoryCardCarouselArrowRight ariaLabel="next photo" tabIndex={tabIndex} />,
      [tabIndex]
    );

    const handleClick = useCallback(e => {
      if (isSwiping.current) {
        e.preventDefault();
      }
    }, []);

    const photoComponents = useMemo(
      () =>
        photos.map((photoUrl, ind) => (
          <CarouselLazySlide key={photoUrl} slideIndex={ind}>
            <UsurpInventoryCardVdpLink
              vehicle={vehicle}
              vdpLink={vdpLink}
              isLlmCard={isLlmCard}
              searchId={searchId}
              isSimilarCards={isSimilarCards}
              isVdpPartnerListing={isVdpPartnerListing}
              tabIndex={-1}
              ariaHidden
              onClick={handleClick}
              isVinPreview={isVinPreview}
              isShop2901Enabled={isShop2901Enabled}
            >
              <UsurpInventoryCardPhotoImage
                imageId={photoUrl}
                source={SOURCES.EDMUNDSCDN}
                isLoadingStateDisabled={ind === 0}
                vin={vin}
                clientSideRenderImage={clientSideRenderImage}
                lazyLoadImage={lazyLoadImage && ind === 0}
                importance={ind === 0 ? importance : null}
                bodyType={bodyType}
                classNames={photoImgClassnames}
              />
            </UsurpInventoryCardVdpLink>
          </CarouselLazySlide>
        )),
      [
        photos,
        vehicle,
        vdpLink,
        isLlmCard,
        searchId,
        isSimilarCards,
        isVdpPartnerListing,
        handleClick,
        isVinPreview,
        isShop2901Enabled,
        vin,
        clientSideRenderImage,
        lazyLoadImage,
        importance,
        bodyType,
        photoImgClassnames,
      ]
    );

    const handleBeforeChange = useCallback((from, to) => {
      isSwiping.current = true;
      setCurrentSlideIndex(to);
    }, []);

    const handleAfterChange = useCallback(() => {
      isSwiping.current = false;
    }, []);

    const metaContextValue = useMemo(
      () => ({
        currentSlide: currentSlideIndex,
      }),
      [currentSlideIndex]
    );

    return (
      <CarouselMetaContext.Provider value={metaContextValue}>
        <Carousel
          swipe
          infinite={false}
          dots
          arrows={!isMobile}
          dotsClass="slick-dots gray-custom d-flex justify-content-center"
          className="inventory-card-carousel"
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
          appendDots={appendDots}
          customPaging={customPaging}
          speed={DEFAULT_ANIMATION_SPEED}
          data-carousel-photo-type={photoType}
          nextArrow={nextArrow}
          prevArrow={prevArrow}
          ariaLive="off" /* we have added aria-live="polite" to the SRP listings and this doesn't play well */
          noAdsRefresh
        >
          {photoComponents}
        </Carousel>
      </CarouselMetaContext.Provider>
    );
  }
);

UsurpInventoryCardCarousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
  photoType: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  vin: PropTypes.string.isRequired,
  clientSideRenderImage: PropTypes.bool,
  lazyLoadImage: PropTypes.bool,
  importance: PropTypes.string,
  bodyType: PropTypes.string,
  vehicle: InventoryEntities.InventoryVin.isRequired,
  vdpLink: PropTypes.string.isRequired,
  isLlmCard: PropTypes.bool,
  searchId: PropTypes.string,
  isSimilarCards: PropTypes.bool,
  isVdpPartnerListing: PropTypes.bool,
  photoImgClassnames: PropTypes.string,
  isVinPreview: PropTypes.bool,
  isShop2901Enabled: PropTypes.bool,
};

UsurpInventoryCardCarousel.defaultProps = {
  isMobile: false,
  tabIndex: 0,
  clientSideRenderImage: false,
  lazyLoadImage: false,
  importance: null,
  bodyType: '',
  isLlmCard: false,
  searchId: undefined,
  isSimilarCards: false,
  isVdpPartnerListing: false,
  photoImgClassnames: '',
  isVinPreview: false,
  isShop2901Enabled: false,
};

UsurpInventoryCardCarousel.displayName = 'UsurpInventoryCardCarousel';
