import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

import './price-label-dealer.scss';

export function PriceLabelDealer({ dealerPrice, msrpPrice, priceStrikethrough }) {
  const priceDescription = dealerPrice ? 'Dealer Price' : 'MSRP';

  if (dealerPrice && msrpPrice) {
    return (
      <div className="price-label-dealer">
        <span className="heading-3">{formatPriceString(dealerPrice)}</span>
        <div
          className={classNames('text-gray-darker fw-normal size-14', {
            'line-through': priceStrikethrough,
          })}
        >
          MSRP {formatPriceString(msrpPrice)}
        </div>
      </div>
    );
  }

  return (
    <div className="price-label-dealer">
      <span
        className={classNames('heading-3', {
          'line-through': priceStrikethrough,
        })}
      >
        {formatPriceString(msrpPrice)}
      </span>
      <div className="text-gray-darker fw-normal size-14">{priceDescription}</div>
    </div>
  );
}

PriceLabelDealer.propTypes = {
  dealerPrice: PropTypes.number,
  msrpPrice: PropTypes.number.isRequired,
  priceStrikethrough: PropTypes.bool,
};

PriceLabelDealer.defaultProps = {
  dealerPrice: 0,
  priceStrikethrough: false,
};
