import React, { Fragment } from 'react';
import { get } from 'lodash';
import dateFormat from 'dateformat';
import { InventoryEntities } from 'client/data/models/inventory';
import { formatPriceString } from 'client/site-modules/shared/utils/price-utils';

export function SrpCardLeaseInfo({ vinData }) {
  const { prices } = vinData;
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);
  const term = get(prices, 'estimateLeasePromise.term', 0);
  const downPayment = get(prices, 'estimateLeasePromise.downPayment', 0);
  const dueAtSigning = get(prices, 'estimateLeasePromise.moneyDueAtSigning', 0);
  const securityDeposit = get(prices, 'estimateLeasePromise.securityDeposit', 0);
  const endDate = get(prices, 'estimateLeasePromise.endDate', '');
  const totalCost = dueAtSigning + leasePayment * (term - 1);

  return (
    <Fragment>
      <p className="small fw-bold">Estimate Lease Details</p>
      <p className="xsmall mb-0_75">
        <span className="fw-bold">{formatPriceString(leasePayment)}</span>/month for {term} months
      </p>
      <div className="mb-1">
        {!!downPayment && (
          <p className="xsmall mb-0">
            <span className="fw-bold">{formatPriceString(downPayment)}</span> Down payment
          </p>
        )}
        {!!dueAtSigning && (
          <p className="xsmall mb-0">
            <span className="fw-bold">{formatPriceString(dueAtSigning)}</span> Due at signing
          </p>
        )}
        {!!securityDeposit && (
          <p className="xsmall mb-0">
            <span className="fw-bold">{formatPriceString(securityDeposit)}</span> Security Deposit
          </p>
        )}
      </div>
      <p className="xsmall">
        {!!dueAtSigning &&
          `${formatPriceString(
            dueAtSigning
          )} total due at lease signing includes down payment, first month payment, and ${formatPriceString(
            securityDeposit
          )} security deposit. `}
        Total cost to lessee is {formatPriceString(totalCost)} over the lease term. Except as otherwise expressly
        provided, excludes sales tax, title, registration and other fees. Lessee is responsible for vehicle maintenance,
        insurance, repairs and charges for excess wear and tear. Actual monthly payments will vary. Not all lessees may
        qualify; higher lease rates apply for lessees with lower credit ratings.{' '}
        {!!endDate &&
          `Payment estimate based on lease programs in effect through ${dateFormat(endDate, 'mmmm d, yyyy')}.`}
      </p>
    </Fragment>
  );
}

SrpCardLeaseInfo.propTypes = {
  vinData: InventoryEntities.InventoryVin,
};

SrpCardLeaseInfo.defaultProps = {
  vinData: {},
};
