import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ellipsisTruncate } from 'client/utils/string-utils';

import './top-feature-bubbles.scss';

function TopFeatureItem({ name }) {
  return (
    <li key={name} className="top-feature-item d-inline-flex align-items-center bg-green-90 mr-0_5 mb-0_5">
      <span className="bg-white-opacity-40 text-nowrap px-0_5 py-0_25 size-12">{ellipsisTruncate(name, 20)}</span>
    </li>
  );
}

TopFeatureItem.propTypes = {
  name: PropTypes.string,
};

TopFeatureItem.defaultProps = {
  name: null,
};
export function TopFeatureBubbles({ topFeatures, className }) {
  if (!topFeatures.length) return null;

  return (
    <ul className={classnames('top-feature-bubbles list-unstyled text-cool-gray-30 fw-medium', className)}>
      {topFeatures.map(({ name }) => (
        <TopFeatureItem key={name} name={name} />
      ))}
    </ul>
  );
}

TopFeatureBubbles.propTypes = {
  topFeatures: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

TopFeatureBubbles.defaultProps = {
  topFeatures: [],
  className: null,
};
