import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, capitalize, isEmpty } from 'lodash';
import dateFormat from 'dateformat';
import { InventoryEntities } from 'client/data/models/inventory';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getFeaturesString } from 'site-modules/shared/utils/inventory-utils/get-features-string';
import { showTrimName } from 'client/site-modules/shared/utils/show-trim-name';
import { transformInventoryComments } from 'site-modules/shared/utils/inventory/seller-comment-utils';
import { SrpCardLeaseInfo } from 'site-modules/shared/components/inventory/srp-card/srp-card-lease-info';
import { MarkdownView } from 'site-modules/shared/components/markdown-view/markdown-view';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { SrpCardVehicleHistory } from './srp-card-vehicle-history';

import './srp-card-more-info.scss';

/**
 * More info block that contains dealer address, comments and distance from customer.
 * @param {object} vinData
 * @param {string} visitorCity
 * @param {string} visitorStateCode
 * @param {boolean} displayLeaseInfo
 * @param {boolean} isSrpExpanded
 * @returns {ReactElement}
 */
export function SrpCardMoreInfo({ vinData, visitorCity, visitorStateCode, displayLeaseInfo, isSrpExpanded }) {
  const sellersComments = transformInventoryComments(get(vinData, 'sellersComments', ''));
  const dealerName = get(vinData, 'dealerInfo.name', '');
  const dealerCity = get(vinData, 'dealerInfo.address.city', '');
  const dealerState = get(vinData, 'dealerInfo.address.stateName', '');
  const stateCode = get(vinData, 'dealerInfo.address.stateCode', '');
  const distance = get(vinData, 'dealerInfo.distance', 0);
  const displayPrice = get(vinData, 'prices.displayPrice', 0);
  const bestDealPrice = get(vinData, 'computedDisplayInfo.transparentPricingCompliance.bestDealPrice', 0);

  const historyInfo = get(vinData, 'historyInfo', {});
  const styleInfo = get(vinData, 'vehicleInfo.styleInfo', {});
  const vin = get(vinData, 'vin', '');
  const stockNumber = get(vinData, 'stockNumber', '');
  const type = get(vinData, 'type', '');
  const year = get(styleInfo, 'year', '');
  const make = get(styleInfo, 'make', '');
  const model = get(styleInfo, 'model', '');
  const trim = get(styleInfo, 'trim', '');
  const fuel = get(styleInfo, 'fuel', {});
  const epaCombinedMPG = get(fuel, 'epaCombinedMPG', 'N/A');
  const epaCityMPG = get(fuel, 'epaCityMPG', 'N/A');
  const epaHighwayMPG = get(fuel, 'epaHighwayMPG', 'N/A');

  const partsInfo = get(vinData, 'vehicleInfo.partsInfo', {});
  const featuresStr = getFeaturesString(vinData);
  const engine = get(partsInfo, 'cylinders', '');
  const transmission = capitalize(get(partsInfo, 'transmission', ''));
  const driveTrain = get(partsInfo, 'driveTrain', '');
  const listedSince = get(vinData, 'listedSince');

  const hasCustomerAddress = distance && visitorCity && visitorStateCode;
  const isUsedType = isUsed(type);
  const labelVehicle = `${year} ${make} ${model} ${showTrimName(trim) ? trim : ''}`;

  return (
    <Fragment>
      {displayLeaseInfo && <SrpCardLeaseInfo vinData={vinData} />}
      {isSrpExpanded ? (
        <p className="small fw-bold">
          Located in {dealerCity}, {stateCode}{' '}
          {!!hasCustomerAddress &&
            `/ ${numberWithCommas(distance)} miles away from ${visitorCity}, ${visitorStateCode}`}
        </p>
      ) : (
        <Fragment>
          <p className="small fw-bold">
            {dealerName} - {dealerCity} / {dealerState}
          </p>
          {!!hasCustomerAddress && (
            <p className="xsmall mb-1">
              Located {numberWithCommas(distance)} miles away from {visitorCity}, {visitorStateCode}
            </p>
          )}
        </Fragment>
      )}
      {!!sellersComments && (
        <FeatureFlag name="srpMarkdown">
          {enabled =>
            enabled ? (
              <MarkdownView markdown={sellersComments} className="sellers-comments" />
            ) : (
              <p className="xsmall mb-1">{sellersComments}</p>
            )
          }
        </FeatureFlag>
      )}
      {bestDealPrice > 0 && (
        <p className="xsmall mb-1">
          MSRP: ${displayPrice}
          <span className="small">*</span>
        </p>
      )}
      {isUsedType && <SrpCardVehicleHistory historyInfo={historyInfo} isSrpExpanded={isSrpExpanded} />}
      <p className="small fw-bold">Features and Specs:</p>
      <p className="xsmall mb-1">
        {!isSrpExpanded && (
          <Fragment>
            Description: {isUsedType ? 'Used' : 'New'} {labelVehicle}
            {featuresStr ? ` with ${featuresStr}` : ''}.<br />
            {engine && (
              <Fragment>
                Engine: {engine}-cylinders <br />
              </Fragment>
            )}
            {transmission && (
              <Fragment>
                Transmission: {transmission} <br />
              </Fragment>
            )}
            {driveTrain && (
              <Fragment>
                Drive Wheel Configuration: {driveTrain} <br />
              </Fragment>
            )}
          </Fragment>
        )}
        {!isEmpty(fuel) && (
          <Fragment>
            {epaCombinedMPG} Combined MPG ({epaCityMPG} City/{epaHighwayMPG} Highway)
          </Fragment>
        )}
      </p>
      <p className="small fw-bold">Listing Information:</p>
      <p className="xsmall mb-1">
        {vin && (
          <Fragment>
            VIN: {vin} <br />
          </Fragment>
        )}
        {stockNumber && (
          <Fragment>
            Stock: {stockNumber} <br />
          </Fragment>
        )}
        {isUsedType && (
          <Fragment>
            Certified Pre-Owned: {isCpo(type) ? 'Yes' : 'No'} <br />
          </Fragment>
        )}
        {listedSince && <Fragment>Listed since: {dateFormat(listedSince, 'mm-dd-yyyy')}</Fragment>}
      </p>
    </Fragment>
  );
}

SrpCardMoreInfo.propTypes = {
  vinData: InventoryEntities.InventoryVin,
  visitorCity: PropTypes.string,
  visitorStateCode: PropTypes.string,
  displayLeaseInfo: PropTypes.bool,
  isSrpExpanded: PropTypes.bool,
};

SrpCardMoreInfo.defaultProps = {
  vinData: {},
  visitorCity: '',
  visitorStateCode: '',
  displayLeaseInfo: false,
  isSrpExpanded: false,
};
